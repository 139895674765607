import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import styles from './styles/SchoolFeedWidget.module.scss';
import {StoreContext} from "../../stores/StoreLoader";
import {Scrollbar} from "react-scrollbars-custom";
import FontAwesome from "../utilities/FontAwesome";
import SchoolFeedList from "./SchoolFeedList";
import {observer} from "mobx-react";
import {ErrorBoundary} from "../utilities/ErrorBoundary";
import {OrganizationTypesWithGrids} from "../../types/OrganizationTypeValues";
import {
    IReactiveEventContentItem,
    IReactiveNewsContentItem,
    ReactiveContentItem,
} from "../../stores/SchoolFeedStore";
import ViewMoreButton from "../blocks/TerracedGridTheme/ViewMoreButton";
import {useFirstPageSchoolFeedContent} from "./hooks/useFirstPageSchoolFeedContent";
import ImageComponent from "../utilities/ImageComponent";
import ClickableLink from "../utilities/ClickableLink";
import {withoutLocaleInURI} from "../../internationalization/i18nURI";
import URI from 'urijs';
import {useTranslation} from "react-i18next";
import i18n from "../../internationalization/i18n";
import ModalBackdrop from "../modals/_ModalBackdrop";
import {color_lightness} from "../../utils/colorcontrast";
import classNames from "classnames"
import WatsonApi from "../../backends/WatsonApi";

export function ContentButtons (props: {
    handleSave: (contentItem: ContentItem, organization: BaseOrganization, post_to_facebook: boolean) => void,
    style: string,
}) {
    const {sidebarStore} = useContext(StoreContext);
    return (
        <div className={props.style}>
            <button onClick={(e) => {
                e.stopPropagation();
                sidebarStore.setSidebar({
                    view: "SchoolFeedPost",
                    handleSave: props.handleSave,
                })
            }}>
                <FontAwesome prefix={'fas'} name={'fa-plus'} />
                Add New Post
            </button>
            <button onClick={(e) => {
                e.stopPropagation();
                sidebarStore.setSidebar({view: "Social"})}
            }>
                <FontAwesome prefix={'fas'} name={'fa-cog'} />
                Sources & Settings
            </button>
        </div>
    )
}

const SchoolFeedWidget: React.FC<{
    organization_id: string,
}> = observer(props => {
    const {organizationStore, userStore, interfaceStore, i18nStore, styleStore} = useContext(StoreContext);
    const isOpen = interfaceStore.schoolFeedWidgetOpen;
    const [contentHeight, setContentHeight] = useState(600);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const { t } = useTranslation('translation', {i18n});

    const {content, isMoreContent, setContent} = useFirstPageSchoolFeedContent({
        organization__path__ancestor_or_eq: organizationStore.currentOrganization.path,
        organization__type__in: OrganizationTypesWithGrids,
    }, isOpen)

    const ref = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const headerRightTextClassName = classNames({
        [styles.headerRightText]: true,
        [styles.headerRightTextThemeColor]: color_lightness(styleStore.appearance.themes[styleStore.appearance.themeID].primaryNavBackground) < 50,
    });

    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener("resize", updateWidthAndHeight);
        return () => window.removeEventListener("resize", updateWidthAndHeight);
    }, []);

    useEffect(() => {
        const primaryNav = document.getElementById("primaryNav");
        const secondaryNav = document.getElementById("sb-secondary-nav");
        if (primaryNav && secondaryNav) {
            const windowHeight = window.innerHeight;
            const headerHeight = primaryNav.clientHeight + secondaryNav.clientHeight;
            if (interfaceStore.zoomValue >= 250) {
                setContentHeight(windowHeight - 55);
            }
            else {
                setContentHeight(windowHeight - headerHeight - 80);
            }
        }
    }, [width, height]);

    useEffect(() => {
        function handleKeyDown(e) {
            if (e.key === "Escape" && interfaceStore.schoolFeedWidgetOpen) {
                interfaceStore.toggleSchoolFeedWidgetOpen();
                const schoolFeedMobileNav = document.getElementById('SchoolFeedMobileNav')
                if (schoolFeedMobileNav) {
                    schoolFeedMobileNav.focus();
                }
                else {
                    buttonRef.current?.focus();
                }
            }
        }
        document.addEventListener("keydown", handleKeyDown);
        return () => document.removeEventListener("keydown", handleKeyDown);
    }, [])

    const onMouseDown = () => {
        interfaceStore.toggleSchoolFeedWidgetOpen();
    }

    function handleDelete(contentItem) {
        setContent(v => v.filter(n => n.id !== contentItem.id));
    }

    return <ErrorBoundary>
        <div className={styles.schoolfeed} data-testid={"schoolfeed-widget"} style={{
            bottom: -contentHeight,
        }}>
            <div className={styles.content} ref={ref} style={{
                transform: `translateY(${isOpen ? -contentHeight : 0}px)`,
            }}>
                <div className={styles.header}>
                    <button onClick={() => interfaceStore.toggleSchoolFeedWidgetOpen()} aria-label={"Toggle SchoolFeed Widget"} tabIndex={0}>
                        <ImageComponent src={require(`../../assets/images/SchoolFeed-logo-white.png`)} className={styles.logo} aria-label={`SchoolFeed Logo`}/>
                    </button>
                    {isOpen ?  <div className={headerRightTextClassName} style={i18nStore.orgDefaultLocale === i18nStore.localeSetByUrl ? {fontSize: '20px'} : {fontSize: '14px'}}>
                        <div className={styles.allLinks}>
                            <div className={styles.schoolFeedLink}>
                                <ClickableLink href={new URI(withoutLocaleInURI("/schoolfeed")).setSearch(
                                    {"type":"news", "org":organizationStore.currentOrganization.id, "orgId":organizationStore.currentOrganization.id}).toString()}><span>{t("News")}</span></ClickableLink>
                            </div>
                            <span>|</span>
                            <div className={styles.schoolFeedLink}>
                                <ClickableLink href={new URI(withoutLocaleInURI("/schoolfeed")).setSearch(
                                    {"type":"events", "org":organizationStore.currentOrganization.id, "orgId":organizationStore.currentOrganization.id}).toString()}>{t("Events")}</ClickableLink>
                            </div>
                            <span>|</span>
                            <div className={styles.schoolFeedLink}>
                                <ClickableLink href={new URI(withoutLocaleInURI("/schoolfeed")).setSearch(
                                    {"type":"files", "org":organizationStore.currentOrganization.id, "orgId":organizationStore.currentOrganization.id}).toString()}>{t("Files")}</ClickableLink>
                            </div>
                            <span>|</span>
                            <div className={styles.schoolFeedLink}>
                                <ClickableLink href={new URI(withoutLocaleInURI("/schoolfeed")).setSearch(
                                    {"type":"media", "org":organizationStore.currentOrganization.id, "orgId":organizationStore.currentOrganization.id}).toString()}>{t("Media")}</ClickableLink>
                            </div>
                        </div>
                    </div>
                         : <div className={`${styles.headerRightText}`} style={i18nStore.orgDefaultLocale === i18nStore.localeSetByUrl ? {fontSize: '20px'} : {fontSize: '16px'}}>
                             {t("SchoolFeed is your center for News and Events")}
                         </div>}
                    <button id={"schoolfeed"} ref={buttonRef} className={styles.openButton} onClick={() => interfaceStore.toggleSchoolFeedWidgetOpen()} aria-label={"Toggle SchoolFeed Widget"} tabIndex={0}>
                        <FontAwesome className={`${styles.popupArrow}`} size={'3x'} name={'fa-angle-right'} prefix={'fas'} style={{transform: isOpen ? "rotate(90deg)" : "rotate(0)"}} />
                    </button>
                </div>
                <div className={styles.contentContainer} style={{
                    height: contentHeight,
                }}>
                    <Scrollbar
                        trackYProps={{
                            style: {width: 6},
                        }}
                        scrollerProps={{
                            tabIndex: 0,
                        }}>
                        {isOpen && <div className={styles.scroller}>
                            {userStore.isEditor && <ContentButtons
                                style={styles.topButtons}
                                handleSave={async (contentItem, organization, post_to_facebook) => {
                                    const client = await WatsonApi();
                                    const result = await client.apis.organizations.organizations_content_items_create({
                                        "organization_pk": organization.id,
                                        "post_to_facebook": post_to_facebook,
                                        data: contentItem,
                                    })
                                    const item = {...JSON.parse(result.data), organization};
                                    const r: unknown = new ReactiveContentItem(item);
                                    setContent(v => [r as IReactiveEventContentItem | IReactiveNewsContentItem, ...v])
                                }}
                            />}
                            <p>SchoolFeed is your center for news and events.</p>
                            <ErrorBoundary>
                                <SchoolFeedList
                                    showMedia={true}
                                    allowCuration={true}
                                    content={content}
                                    handleDelete={handleDelete}
                                    totalItemCount={content.length}
                                    getItemAtIndex={idx => {
                                        return content[idx] as ReactiveContentItem || null;
                                    }}
                                />
                                {isMoreContent && <div className={styles.fetchMoreContainer}>
                                    <ViewMoreButton organizationId={organizationStore.organization.id} text={"View More News"} />
                                </div>}
                            </ErrorBoundary>
                        </div>}
                    </Scrollbar>
                </div>
            </div>
        </div>
        {isOpen && <ModalBackdrop
            active={true}
            onMouseDown={onMouseDown}
            zIndex={500}
        />}
    </ErrorBoundary>
});

export default SchoolFeedWidget;
